// This is a file that can be overridden by the application in order to override styles
// Notice that this file is included at the very end of the stylesheets packs to have
// more priority
//
// To override the Tailwind CSS configuration you can do so by following the instructions
// available at https://docs.decidim.org/en/develop/customize/styles.html#_tailwind_css
//
// By default this is empty.

.mr-5 {
  margin-right: 1.25rem;
}

.form__wrapper-checkbox-label {
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
  color: rgb(2, 2, 3);
}

.text-capitalize {
  text-transform: capitalize;
}

label[for="project_questionnaire_description"],
label[for="project_questionnaire_public_accessibility"],
label[for="project_questionnaire_purpose"],
label[for="other_attachments"],
label[for="attachments"] {
  --tw-text-opacity: 1;
  color: rgb(var(--secondary-rgb) / 1);
  color: rgb(var(--secondary-rgb) / var(--tw-text-opacity));
  font-size: 24px !important;
  line-height: 30px !important;
  font-weight: 600 !important;
}

.editor label {
  --tw-text-opacity: 1;
  color: rgb(var(--secondary-rgb) / 1);
  color: rgb(var(--secondary-rgb) / var(--tw-text-opacity));
  font-size: 24px !important;
  line-height: 30px !important;
  font-weight: 600 !important;
}

.form__wrapper {
  gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.is-invalid-label {
  color: rgb(var(--alert-rgb));
}

// .input-character-counter__text {
//   // margin-top: 0.5rem;
//   // font-size: 14px;
//   // line-height: 18px;
//   // font-weight: 400;
//   // --tw-text-opacity: 1;
//   color: rgb(var(--alert-rgb) / var(--tw-text-opacity)) !important;
// }

.vertical-tabs {
  border: 1px solid #ddd;
  padding-right: 3rem;
  padding-bottom: 0;
  border-radius: 5px;

  .h2 {
    margin-top: 2rem;
  }
}


#content>main>div>section {
  padding-bottom: 3rem;
}


#content>main>div>nav {
  height: 100%;
  background: #F3F4F7;
  border-right: 1px solid #e8e8e8;
  margin-right: -42px;
}

.filter-help,
#meetings>a {
  display: none;
}

header .main-bar__links-mobile {
  position: fixed;
  top: 0px;
  bottom: auto;
  left: 0px;
  z-index: 40;
  display: flex;
  width: 100%;
  justify-content: space-between;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / 1);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  --tw-text-opacity: 1;
  color: rgb(var(--secondary-rgb) / 1);
  color: rgb(var(--secondary-rgb) / var(--tw-text-opacity));
  --tw-shadow: 0 -4px 6px rgba(198, 198, 198, 0.25);
  --tw-shadow-colored: 0 -4px 6px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 -4px 6px rgba(198, 198, 198, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (max-width: 1024px) {
  header .admin-bar__container {
    padding-top: 5.5rem !important;
  }
}

@media (max-width: 450px) {
  header .admin-bar__container {
    padding-top: 6.5rem !important;
  }
}

/* Custom Dropdown Button */
.custom-dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 14px;
  padding-top: 14px !important;
  line-height: normal;
  font-size: 19px;
  border: none;
  border-radius: 3px;
  width: 100%;
  text-align: left;
}

/* The container <div> - needed to position the dropdown content */
.custom-dropdown {
  position: relative;
  display: inline-block;
  padding: 0;
  width: 100%;
}

/* Custom Dropdown Content (Hidden by Default) */
.custom-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.custom-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.custom-dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.custom-dropdown:hover .custom-dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.custom-dropdown:hover .custom-dropbtn {
  background-color: #3e8e41;
}

#content>main>div>section>span,
#content>main>div>section>div>span,
#content>main>div>section>section>div.verification__container-title {
  padding-top: 15px;
}